import { navigate } from "gatsby"
import React, { useState } from "react"
import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container"
import Modal from "react-bootstrap/Modal"
import Row from "react-bootstrap/Row"
import "react-datepicker/dist/react-datepicker.css"
import Layout from "../components/utils/layout"
import SEO from "../components/utils/seo"
import TitleLine from "../components/utils/TitleLine"
import editProject from "../scripts/editProject"
import getUserData from "../scripts/getUserData"
import "../styles/SignUpPage.css"
import PickDate from "../components/utils/PickDate"
import repostProject from "../scripts/repostProject"
import { auth, default as firebase } from "../firebase"
import InputCreator from "../components/styledInputs/InputCreator"
import NewAsyncButton from "../components/utils/NewAsyncButton"
import dots from "../images/bgs/dots.svg"

function InvalidInput(props) {
  const [show, setShow] = useState(false)
  if (props.modalText === "Saving Changes...") {
    props.postTask()
  }
  const handleClose = () => {
    setShow(false)
    // props.resetModalState()
  }
  const handleShow = () => {
    props.setClicked()
    setShow(true)
  }
  if (props.modalText === "Saved!") {
    return (
      <>
        <Button variant="primary" onClick={handleShow}>
          Edit Project
        </Button>

        <Modal show={show} onHide={handleClose} style={{ zIndex: "9999999" }}>
          <Modal.Header closeButton>
            <Modal.Title>Thank you for editing your project!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            This project is now updated with your changes.
            <br></br>
            <br></br>
            Please note that the project cannot be changed once a student is
            chosen.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => navigate("/teacher-dashboard/")}
            >
              Go back to my portal
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  } else {
    return (
      <>
        <Button variant="primary" onClick={handleShow}>
          Save
        </Button>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Project</Modal.Title>
          </Modal.Header>
          <Modal.Body>{props.modalText}</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

class EditATask extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,

      authenticated: null,

      teacherEmail: "",
      whatWeNeedCount: 0,
      skillsCount: 0,
      howThisWillHelpCount: 0,
      clicked: false,
      modalText: "Saving Changes...",
      mode: "Edit",
      dueDate: null,
      seriesOne: {
        whatWeNeed: "",
        necessarySkills: "",
        howThisWillHelp: "",
        estTime: 0,
        taskName: "",
      },
      errors: [false, false, false, false, false],
    }
    this.init = this.init.bind(this)
    this.postTask = this.postTask.bind(this)
    this.setClicked = this.setClicked.bind(this)
    this.resetModalState = this.resetModalState.bind(this)
    this.setDate = this.setDate.bind(this)
  }

  init() {
    if (this.state.authenticated) {
      this.setState({ initialized: true })
      let user = auth.currentUser
      let mode = window.history.state.from
      this.setState({ mode: mode })
      getUserData(user.uid)
        .then(userData => {
          if (userData["userType"] !== "teacher") {
            this.navigateTo()
          }
          let data = window.history.state.taskInfo
          this.setState({
            seriesOne: {
              whatWeNeed: data["whatWeNeed"],
              necessarySkills: data["necessarySkills"],
              howThisWillHelp: data["howThisWillHelp"],
              whatWeNeedCount: data["whatWeNeed"].length,
              skillsCount: data["necessarySkills"].length,
              howThisWillHelpCount: data["howThisWillHelp"].length,
              taskName: data["taskName"],
              estTime: data["estTime"],
              dueDate: data["dueDate"],
            },
          })
          this.setDate(new Date(data.dueDate.seconds * 1000))
        })
        .catch(error => {
          console.log(error)
          //TODO ERROR HANDLE
        })
    }
  }

  resetModalState() {
    this.setState({ modalText: "Saving Changes..." })
  }
  setClicked() {
    this.setState({ clicked: true })
  }

  navigateTo() {
    navigate("/404/")
  }

  postTask(reset) {
    if (
      !this.state.seriesOne.taskName ||
      !this.state.seriesOne.estTime ||
      !this.state.dueDate ||
      !this.state.seriesOne.whatWeNeed ||
      !this.state.seriesOne.necessarySkills ||
      !this.state.seriesOne.howThisWillHelp
    ) {
    } else {
      if (this.state.mode === "edit") {
        let postData = {
          taskName: this.state.seriesOne.taskName,
          estTime: this.state.seriesOne.estTime,
          dueDate: firebase.firestore.Timestamp.fromDate(this.state.dueDate),
          whatWeNeed: this.state.seriesOne.whatWeNeed,
          necessarySkills: this.state.seriesOne.necessarySkills,
          howThisWillHelp: this.state.seriesOne.howThisWillHelp,
        }
        editProject(window.history.state.taskID, postData)
          .then(() => {
            reset("Saved!")
          })
          .catch(error => {
            console.log(error)
            reset("Error editing task. Try again?")
            //TODO ERROR HANDLE
          })
      } else {
        let postData = {
          taskName: this.state.seriesOne.taskName,
          estTime: this.state.seriesOne.estTime,
          dueDate: firebase.firestore.Timestamp.fromDate(this.state.dueDate),
          whatWeNeed: this.state.seriesOne.whatWeNeed,
          necessarySkills: this.state.seriesOne.necessarySkills,
          howThisWillHelp: this.state.seriesOne.howThisWillHelp,
          acceptedDate: firebase.firestore.FieldValue.delete(),
          status: "pending",
          studentIDs: [],
          studentNames: [],
          studentEmails: [],
          studentSchools: [],
          hoursLog: [],
          signups: [],
          postedDate: firebase.firestore.Timestamp.now(),
        }
        repostProject(window.history.state.taskID, postData)
          .then(() => {
            reset("Saved!")
          })
          .catch(error => {
            console.log(error)
            reset("Error posting task. Try Again?")
          })
      }
    }
  }

  componentDidMount() {
    auth.onAuthStateChanged(user => {
      if (!user) {
        this.setState({ authenticated: false })
      } else {
        this.setState({ authenticated: true })
      }
    })
  }

  setDate(date) {
    this.setState({ dueDate: date })
  }

  handleForm = event => {
    let tmp = this.state.seriesOne
    tmp[event.target.name] = event.target.value
    this.setState({ seriesOne: tmp })
    this.resetModalState()
  }

  onFocus = event => {
    const order = [
      "taskName",
      "estTime",
      "whatWeNeed",
      "necessarySkills",
      "howThisWillHelp",
    ]
    let newErrors = this.state.errors
    newErrors[order.indexOf(event.target.name)] = false
    this.setState({ errors: newErrors })
  }

  onBlur = event => {
    const order = [
      "taskName",
      "estTime",
      "whatWeNeed",
      "necessarySkills",
      "howThisWillHelp",
    ]
    let newErrors = this.state.errors
    if (event.target.value === 0 || event.target.value.trim() === "") {
      newErrors[order.indexOf(event.target.name)] = true
    } else if (typeof event.target.value === "string") {
      this.setState({ [event.target.name]: event.target.value.trim() })
    }
    this.setState({ errors: newErrors })
  }

  render() {
    const regularInput = [
      "Project Name",
      "Estimated Completion Time (in hours)",
      "What We Need",
      "Preferred Skills",
      "How This Will Help",
    ]
    const inputType = ["text", "number", "textarea", "textarea", "textarea"]
    const inputNames = [
      "taskName",
      "estTime",
      "whatWeNeed",
      "necessarySkills",
      "howThisWillHelp",
    ]

    if (!this.state.initialized) {
      this.init()
    }
    return (
      <>
        <Container className="topSpacing">
          <>
            <TitleLine
              title={
                this.state.mode === "edit" ? "Edit Project" : "Repost Project"
              }
              description={
                this.state.mode === "edit"
                  ? "This is where you can edit your project."
                  : "This is where you can repost your project."
              }
            />
            {this.state.mode === "edit" && (
              <>
                <InputCreator
                  titles={regularInput}
                  errors={this.state.errors}
                  types={inputType}
                  names={inputNames}
                  struct={[1, 1, 1, 1, 1]}
                  rows={[1, 1, 4, 4, 4]}
                  callback={this.handleForm}
                  values={this.state.seriesOne}
                  // onBlur={this.onBlur}
                  // onFocus={this.onFocus}
                />
              </>
            )}
            <Container className="justify-content-md-center dueDate">
              <Row className="justify-content-md-center dueDate">
                <span className="dueDateFont">Due Date</span>
              </Row>
              <Row className="justify-content-md-center">
                <PickDate setDate={this.setDate} date={this.state.dueDate} />
              </Row>
            </Container>
            <Row
              className=" justify-content-center sidesPadding center"
              style={{ marginTop: "35px" }}
            >
              <NewAsyncButton
                buttonTexts={[
                  "Edit Task",
                  "Saving Task...",
                  "Error saving task. Try Again?",
                  "Successfully Edited!",
                ]}
                buttonVariants={["primary", "danger", "danger", "success"]}
                buttonColor={["", "", "", ""]}
                callback={this.postTask}
              />
            </Row>
            {/* <div className="selector">
              <div class="center">
                <InvalidInput
                  modalText={this.state.modalText}
                  resetModalState={this.resetModalState}
                  postTask={this.postTask}
                  setClicked={this.setClicked}
                />
              </div>
            </div> */}
          </>
        </Container>
      </>
    )
  }
}
const EditTask = () => (
  <Layout image={dots}>
    <SEO title="Edit Project" />
    <EditATask />
  </Layout>
)

export default EditTask
