import { db, default as firebase } from "../firebase"

export default async function repostProject(projectID, newData) {
  return db.runTransaction(async transaction => {
    let snap = await transaction.get(db.collection("projects").doc(projectID))

    transaction.update(snap.ref, newData)
    transaction.update(db.collection("users").doc(snap.data().teacherID), {
      activeProjects: firebase.firestore.FieldValue.arrayRemove(projectID),
      pendingProjects: firebase.firestore.FieldValue.arrayUnion(projectID),
    })
    snap.data().studentIDs.map((value, index) => {
      transaction.update(db.collection("users").doc(value), {
        activeProjects: firebase.firestore.FieldValue.arrayRemove(projectID),
      })
      return null
    })
  })
}
